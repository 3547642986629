/*
import { createRouter, createWebHistory } from 'vue-router';
import Page5 from '../components/Page5.vue';

const routes = [

  {
    path: '/pindai',
    name: 'Page5',
    component: Page5,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;

*/

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PindaiView from '../views/PindaiView.vue'

const routes = [
  {
    path: '/',
    redirect: '/undangansetrum'
  },
  {
    path: '/undangansetrum/:userid',
    name: 'HomeView',
    component: HomeView,
    props: true
  },
  {
    path: '/pindai',
    name: 'PindaiView',
    component: PindaiView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
