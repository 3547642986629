
<template>
  <swiper :slidesPerView="1" pagination navigation>
    <swiper-slide><Page1 /></swiper-slide>
    <swiper-slide><Page2 /></swiper-slide>
    <swiper-slide><Page3 /></swiper-slide>
    <swiper-slide><Page4 :userid="userid"/></swiper-slide>
    <swiper-slide><Page5 /></swiper-slide>
  </swiper>
</template>

<script>
import Page1 from '../components/Page1.vue'
import Page2 from '../components/Page2.vue'
import Page3 from '../components/Page3.vue'
import Page4 from '../components/Page4.vue'
import Page5 from '../components/Page5.vue'



export default {
  name: 'App',
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5
  },
  props: ['userid']
}
</script>

