
<template>
  <div class="page">
  
  <!-- Your page content -->
  <div class="image-container">
    <img class="image" :src="picture4" alt="Undangan Setrum">  
  </div>

  <div class="overlay">
    <qrcode-vue :value="userid" size="230" level="H"></qrcode-vue>
    <!--qrcode-vue :value="extractedId" size="230" level="H"></qrcode-vue-->
  </div>
  
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import picture4 from '@/assets/Picture4.png';

export default {
  name: "Page4",
  data() {
    return {
      picture4: picture4,
      //extractedId: ''
    };
  },
  components: {
    QrcodeVue
  },
  props: ['userid'],
  /*mounted() {
    this.extractedId = this.extractIdFromUserId(this.userid);
  },
  methods: {
    extractIdFromUserId(userid) {
      // Split the userid by "-"
      const parts = userid.split('-');
      
      // Check if the userid is in the expected format
      if (parts.length === 2) {
        // Return the second part of the split string
        return parts[1];
      } else {
        // Handle invalid userid format
        console.error('Invalid userid format');
        return '';
      }
    }
  }
  */


};
</script>


<style scoped>

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: 'Roboto', sans-serif; /* Beautiful font */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the container's height to 100% of the viewport height */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}

.image {
  height: 80%; /* Make the image fill its container vertically */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container while preserving aspect ratio */
}


.overlay {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;


  width: 250px; /* Set the width of the overlay */
  height: 250px; /* Set the height of the overlay */
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  background: white; /*rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Make the overlay invisible initially */
  transition: opacity 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
}
</style>