<template>
  <div>
    <h1>This is View 1</h1>
  </div>
</template>

<script>
export default {
  name: 'View1'
}
</script>
