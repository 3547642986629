

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'



// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const app = createApp(App)

app.component('Swiper', Swiper)
app.component('SwiperSlide', SwiperSlide)
app.use(router).mount('#app')

