
<!--template>
  <swiper :slidesPerView="1" pagination navigation>
    <swiper-slide><Page1 /></swiper-slide>
    <swiper-slide><Page2 /></swiper-slide>
    <swiper-slide><Page3 /></swiper-slide>
    <swiper-slide><Page4 /></swiper-slide>
  </swiper>
</template>

<script>
import Page1 from './components/Page1.vue'
import Page2 from './components/Page2.vue'
import Page3 from './components/Page3.vue'
import Page4 from './components/Page4.vue'
import Page5 from './components/Page5.vue'



export default {
  name: 'App',
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5
  },
}
</script-->

<template>
  <div
    v-touch:swipe.left="swipeLeft"
    v-touch:swipe.right="swipeRight"
    class="swipe-container"
  >
    <router-view/>
  </div>
</template>

<script>
export default {
  methods: {
    swipeLeft() {
      const currentPath = this.$route.path;
      const nextRoute = getNextRoute(currentPath, 'left');
      if (nextRoute) {
        this.$router.push(nextRoute);
      }
    },
    swipeRight() {
      const currentPath = this.$route.path;
      const nextRoute = getNextRoute(currentPath, 'right');
      if (nextRoute) {
        this.$router.push(nextRoute);
      }
    }
  }
}

function getNextRoute(currentPath, direction) {
  const routeOrder = ['/view1', '/view2', '/view3', '/view4'];
  const currentIndex = routeOrder.indexOf(currentPath);
  if (direction === 'left' && currentIndex < routeOrder.length - 1) {
    return routeOrder[currentIndex + 1];
  } else if (direction === 'right' && currentIndex > 0) {
    return routeOrder[currentIndex - 1];
  }
  return null;
}
</script>

<style>
.swipe-container {
  width: 100%;
  height: 100%;
}
</style>


