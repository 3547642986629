
<template>
  <div class="page">
    <!-- Your page content -->
    <div class="image-container">
      <img class="image" :src="picture5" alt="Undangan Setrum">
      
    </div>
  </div>
</template>

<script>
import picture5 from '@/assets/Picture5.png';

export default {
  name: "Page5",
  data() {
    return {
      picture5: picture5
    };
  }

};
</script>

<style scoped>

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: 'Roboto', sans-serif; /* Beautiful font */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the container's height to 100% of the viewport height */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}

.image {
  height: 80%; /* Make the image fill its container vertically */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container while preserving aspect ratio */
}
</style>